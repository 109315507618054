<template>
  <div id="card-body-wrapper" class="card-body">
    <div
      class="d-flex align-items-center justify-content-center mb-4"
      v-if="page"
    >
      <div class="mx-1">Page</div>
      <a-select class="mx-1" v-model="selectPage">
        <a-select-option v-for="n in totalPage" :value="n" :key="n">{{
          n
        }}</a-select-option>
        <a-select-option value="rewardPunishment"
          >Reward and Punishment Report</a-select-option
        >
      </a-select>
      <template v-if="selectPage != 'rewardPunishment'">
        <div class="mx-1">Of</div>
        <div class="mx-1">{{ totalPage }}</div>
      </template>
    </div>
    <div v-if="generalReport" ref="pdfContent" id="pdf-content">
      <!-- {{generalReport.report_mapels}} -->
      <div
        v-if="!page || (page && selectPage === 1)"
        :style="{ ...format, position: 'relative' }"
        class="font-size-12"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img
              :src="institusi ? institusi.logo : `/${configSchool.logo}`"
              width="120px"
              alt
            />
          </div>
          <div class="ml-5">
            <h3 class="text-uppercase font-size-30">Student Progress Report</h3>
          </div>
        </div>
        <hr />
        <table class="table table-borderless mt-3">
          <tr class="font-size-12">
            <td class="table-title py-1">School Name</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1">
              {{ institusi ? institusi.nama : configSchool.name2 }}
            </td>
            <td class="table-title py-1">Class</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                generalReport.kelas_tahun_ajaran.kela.simbol
              }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">Address</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1" style="width: 35%">
              {{ institusi ? institusi.alamat : configSchool.address }}
            </td>
            <td class="table-title py-1">Academic</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.tahun }} /
              {{
                generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester ===
                "Genap"
                  ? 2
                  : 1
              }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">Student Name</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.murid.nama }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">NIS</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.murid.nis }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">NISN</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.murid.nisn }}
            </td>
          </tr>
        </table>
        <hr />
        <div class="mt-5 font-size-12">
          <ol class="ol-alphabet">
            <li class="font-weight-bold text-uppercase"><h5>Attitude</h5></li>
            <div class="mt-2">
              <h6 class="text-uppercase">1. Spiritual Attitude</h6>
              <table class="table table-bordered text-center mt-1">
                <tr>
                  <td class="w-25">Predicate</td>
                  <td class="w-75">Description</td>
                </tr>
                <tr>
                  <td class="align-middle">
                    {{
                      checkString("spiritual", generalReport.spiritual).alias
                    }}
                  </td>
                  <td>
                    {{
                      checkString("spiritual", generalReport.spiritual)
                        .description
                    }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="mt-2">
              <h6 class="text-uppercase">2. Social Attitude</h6>
              <table class="table table-bordered text-center mt-1">
                <tr>
                  <td class="w-25">Predicate</td>
                  <td class="w-75">Description</td>
                </tr>
                <tr>
                  <td>
                    {{
                      checkString("social", generalReport.socialPredicateOption)
                        .alias
                    }}
                  </td>
                  <td>
                    {{
                      checkString("social", generalReport.socialPredicateOption)
                        .description
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </ol>
        </div>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 40px;
          "
        >
          <hr class="mb-1" />
          <div class="d-flex justify-content-between mt-1 px-2">
            <div>
              {{ generalReport.kelas_tahun_ajaran.murid.nama }} /
              {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                generalReport.kelas_tahun_ajaran.kela.simbol
              }}
              /
              {{
                generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester ===
                "Genap"
                  ? 2
                  : 1
              }}
            </div>
            <div>page 1 of {{ totalPage }}</div>
          </div>
        </div>
      </div>

      <template
        v-if="!page || (page && (selectPage === 2 || selectPage === 3))"
      >
        <!-- page 2 -->
        <div
          v-if="!page || selectPage === 2"
          :style="{ ...format, position: 'relative' }"
          class="font-size-12"
        >
          <!-- margin -->
          <!-- <div v-if="!page" style="height: 50px" /> -->
          <ol class="ol-alphabet" :start="2">
            <li class="font-weight-bold text-uppercase">
              <h5>Knowledge and skill</h5>
            </li>
            <div class="mt-2">
              <h6 class="text-uppercase">1. Knowledge</h6>
              <table class="table table-bordered mt-1">
                <tr class="text-center">
                  <td class="align-middle" rowspan="2">No.</td>
                  <td class="align-middle w-25" rowspan="2">Subject</td>
                  <td class="align-middle" rowspan="2">KBM</td>
                  <td colspan="3" rowspan="1">Knowledge</td>
                </tr>
                <tr class="text-center">
                  <td rowspan="1">Grade</td>
                  <td rowspan="1">Remark</td>
                  <td rowspan="1">Description</td>
                </tr>
                <template v-for="item in generalReport.listGroups">
                  <tr :key="item.id + 'header'">
                    <td class="font-size-12" colspan="7">{{ item.nama }}</td>
                  </tr>
                  <tr
                    :style="{ height: !page ? tableGradeHeight + 'px' : null }"
                    v-for="(mapel, index) in mapels(
                      generalReport.report_mapels,
                      item.nama
                    )"
                    :key="mapel.id"
                  >
                    <td class="align-middle text-center">
                      {{ numberIndex(index) }}
                    </td>
                    <td class="align-middle">
                      {{
                        mapel.mata_pelajaran.english_name !== null
                          ? mapel.mata_pelajaran.english_name
                          : mapel.mata_pelajaran.nama
                      }}
                    </td>
                    <td class="align-middle text-center">
                      {{
                        mapel.mata_pelajaran.kbms.length
                          ? mapel.mata_pelajaran.kbms[0].nilai
                          : "-"
                      }}
                    </td>
                    <td class="align-middle text-center">
                      {{
                        mapel.nilai_pengetahuan !== null
                          ? Math.round(mapel.nilai_pengetahuan)
                          : "-"
                      }}
                    </td>
                    <td class="align-middle text-center">
                      {{
                        scoring(
                          mapel.nilai_pengetahuan,
                          mapel.mata_pelajaran.kbms
                        ).remark
                      }}
                    </td>
                    <td
                      class="text-left pl-2 align-middle"
                      style="font-size: 11px"
                    >
                      {{
                        checkString(
                          "knowledge",
                          scoring(
                            mapel.nilai_pengetahuan,
                            mapel.mata_pelajaran.kbms
                          ).remark
                        ).description
                      }}
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </ol>
          <div
            v-if="print"
            style="
              position: absolute;
              bottom: 0;
              width: 100%;
              margin-bottom: 40px;
            "
          >
            <hr class="mb-1" />
            <div class="d-flex justify-content-between mt-1 px-2">
              <div>
                {{ generalReport.kelas_tahun_ajaran.murid.nama }} /
                {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                  generalReport.kelas_tahun_ajaran.kela.simbol
                }}
                /
                {{
                  generalReport.kelas_tahun_ajaran.kela.tahun_ajaran
                    .semester === "Genap"
                    ? 2
                    : 1
                }}
              </div>
              <div>page 2 of {{ totalPage }}</div>
            </div>
          </div>
        </div>

        <!-- page 3 -->
        <div
          v-if="!page || selectPage === 3"
          :style="{ ...format, position: 'relative' }"
          class="font-size-12"
        >
          <!-- <div v-if="!page" style="height: 50px" /> -->
          <div class="pl-5">
            <table class="table table-bordered mt-1">
              <tr class="text-center">
                <td class="align-middle" rowspan="2">No.</td>
                <td class="align-middle w-25" rowspan="2">Subject</td>
                <td class="align-middle" rowspan="2">KBM</td>
                <td colspan="3" rowspan="1">Knowledge</td>
              </tr>
              <tr class="text-center">
                <td rowspan="1">Grade</td>
                <td rowspan="1">Remark</td>
                <td rowspan="1">Description</td>
              </tr>
              <tr>
                <td class="font-size-12" colspan="7">Group B</td>
              </tr>
              <tr
                v-for="(mapel, index) in mapels(
                  generalReport.report_mapels,
                  'B'
                )"
                :key="mapel.id"
              >
                <td
                  :style="{ height: !page ? tableGradeHeight + 'px' : null }"
                  class="align-middle text-center"
                >
                  {{ numberIndex(index) }}
                </td>
                <td class="align-middle">
                  {{
                    mapel.mata_pelajaran.english_name !== null
                      ? mapel.mata_pelajaran.english_name
                      : mapel.mata_pelajaran.nama
                  }}
                </td>
                <td class="align-middle text-center">
                  {{
                    mapel.mata_pelajaran.kbms.length
                      ? mapel.mata_pelajaran.kbms[0].nilai
                      : "-"
                  }}
                </td>
                <td class="align-middle text-center">
                  {{
                    mapel.nilai_pengetahuan !== null
                      ? Math.round(mapel.nilai_pengetahuan)
                      : "-"
                  }}
                </td>
                <td class="align-middle text-center">
                  {{
                    scoring(mapel.nilai_pengetahuan, mapel.mata_pelajaran.kbms)
                      .remark
                  }}
                </td>
                <td class="text-left pl-2 align-middle" style="font-size: 11px">
                  {{
                    checkString(
                      "knowledge",
                      scoring(
                        mapel.nilai_pengetahuan,
                        mapel.mata_pelajaran.kbms
                      ).remark
                    ).description
                  }}
                </td>
              </tr>
            </table>
          </div>
          <div
            v-if="print"
            style="
              position: absolute;
              bottom: 0;
              width: 100%;
              margin-bottom: 40px;
            "
          >
            <hr class="mb-1" />
            <div class="d-flex justify-content-between mt-1 px-2">
              <div>
                {{ generalReport.kelas_tahun_ajaran.murid.nama }} /
                {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                  generalReport.kelas_tahun_ajaran.kela.simbol
                }}
                /
                {{
                  generalReport.kelas_tahun_ajaran.kela.tahun_ajaran
                    .semester === "Genap"
                    ? 2
                    : 1
                }}
              </div>
              <div>page 3 of {{ totalPage }}</div>
            </div>
          </div>
        </div>
      </template>

      <!-- page 4 -->
      <div
        v-if="!page || (page && selectPage === 4)"
        :style="{ ...format, position: 'relative' }"
        class="font-size-12"
      >
        <!-- <div v-if="!page" style="height: 50px" /> -->
        <div class="pl-5">
          <h6 class="text-uppercase">2. Skill</h6>
          <table class="table table-bordered mt-1">
            <tr class="text-center">
              <td class="align-middle" rowspan="2">No.</td>
              <td class="align-middle w-25" rowspan="2">Subject</td>
              <td class="align-middle" rowspan="2">KBM</td>
              <td colspan="3" rowspan="1">Skill</td>
            </tr>
            <tr class="text-center">
              <td rowspan="1">Grade</td>
              <td rowspan="1">Remark</td>
              <td rowspan="1">Description</td>
            </tr>
            <tr>
              <td class="font-size-12" colspan="7">Group A</td>
            </tr>
            <tr
              v-for="(mapel, index) in mapels(generalReport.report_mapels, 'A')"
              :key="mapel.id"
            >
              <td
                :style="{ height: !page ? tableGradeHeight + 'px' : null }"
                class="align-middle text-center"
              >
                {{ numberIndex(index) }}
              </td>
              <td class="align-middle">
                {{
                  mapel.mata_pelajaran.english_name !== null
                    ? mapel.mata_pelajaran.english_name
                    : mapel.mata_pelajaran.nama
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  mapel.mata_pelajaran.kbms.length
                    ? mapel.mata_pelajaran.kbms[0].nilai
                    : "-"
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  mapel.nilai_keterampilan !== null
                    ? Math.round(mapel.nilai_keterampilan)
                    : "-"
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  scoring(mapel.nilai_keterampilan, mapel.mata_pelajaran.kbms)
                    .remark
                }}
              </td>
              <td class="text-left pl-2 align-middle" style="font-size: 11px">
                {{
                  checkString(
                    "skill",
                    scoring(mapel.nilai_keterampilan, mapel.mata_pelajaran.kbms)
                      .remark
                  ).description
                }}
              </td>
            </tr>
          </table>
        </div>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 40px;
          "
        >
          <hr class="mb-1" />
          <div class="d-flex justify-content-between mt-1 px-2">
            <div>
              {{ generalReport.kelas_tahun_ajaran.murid.nama }} /
              {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                generalReport.kelas_tahun_ajaran.kela.simbol
              }}
              /
              {{
                generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester ===
                "Genap"
                  ? 2
                  : 1
              }}
            </div>
            <div>page 4 of {{ totalPage }}</div>
          </div>
        </div>
      </div>

      <!-- page 5 -->
      <div
        v-if="!page || (page && selectPage === 5)"
        :style="{ ...format, position: 'relative' }"
        class="font-size-12"
      >
        <!-- <div v-if="!page" style="height: 50px" /> -->
        <div class="pl-5">
          <table class="table table-bordered mt-1">
            <tr class="text-center">
              <td class="align-middle" rowspan="2">No.</td>
              <td class="align-middle w-25" rowspan="2">Subject</td>
              <td class="align-middle" rowspan="2">KBM</td>
              <td colspan="3" rowspan="1">Skill</td>
            </tr>
            <tr class="text-center">
              <td rowspan="1">Grade</td>
              <td rowspan="1">Remark</td>
              <td rowspan="1">Description</td>
            </tr>
            <tr>
              <td class="font-size-12" colspan="7">Group B</td>
            </tr>
            <tr
              v-for="(mapel, index) in mapels(generalReport.report_mapels, 'B')"
              :key="mapel.id"
            >
              <td
                :style="{ height: !page ? tableGradeHeight + 'px' : null }"
                class="align-middle text-center"
              >
                {{ numberIndex(index) }}
              </td>
              <td class="align-middle">
                {{
                  mapel.mata_pelajaran.english_name !== null
                    ? mapel.mata_pelajaran.english_name
                    : mapel.mata_pelajaran.nama
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  mapel.mata_pelajaran.kbms.length
                    ? mapel.mata_pelajaran.kbms[0].nilai
                    : "-"
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  mapel.nilai_keterampilan !== null
                    ? Math.round(mapel.nilai_keterampilan)
                    : "-"
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  scoring(mapel.nilai_keterampilan, mapel.mata_pelajaran.kbms)
                    .remark
                }}
              </td>
              <td class="align-middle text-left pl-2" style="font-size: 11px">
                {{
                  checkString(
                    "skill",
                    scoring(mapel.nilai_keterampilan, mapel.mata_pelajaran.kbms)
                      .remark
                  ).description
                }}
              </td>
            </tr>
          </table>
        </div>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 40px;
          "
        >
          <hr class="mb-1" />
          <div class="d-flex justify-content-between mt-1 px-2">
            <div>
              {{ generalReport.kelas_tahun_ajaran.murid.nama }} /
              {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                generalReport.kelas_tahun_ajaran.kela.simbol
              }}
              /
              {{
                generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester ===
                "Genap"
                  ? 2
                  : 1
              }}
            </div>
            <div>page 5 of {{ totalPage }}</div>
          </div>
        </div>
        <!-- asd: {{generalReport.kelas_tahun_ajaran.peserta_ekstrakurikulers.length}} -->
      </div>

      <!-- page 6 -->
      <div
        v-if="!page || (page && selectPage === 6)"
        :style="{ ...format, position: 'relative' }"
        class="font-size-12"
      >
        <!-- <div v-if="!page" style="height: 50px" /> -->
        <ol class="ol-alphabet" :start="3">
          <li class="font-weight-bold text-uppercase">
            <h5>Extracurricular</h5>
          </li>
          <div class="mt-2">
            <table class="table-bordered mt-1 w-100" style="font-size: 11px">
              <tr style="height: 30px" class="text-center">
                <td style="width: 10%">No</td>
                <td>Extracurricular</td>
                <td>Predicate</td>
                <td>Description</td>
              </tr>
              <template
                v-if="
                  generalReport.kelas_tahun_ajaran &&
                  generalReport.kelas_tahun_ajaran.peserta_ekstrakurikulers &&
                  generalReport.kelas_tahun_ajaran.peserta_ekstrakurikulers
                    .length
                "
              >
                <tr
                  style="height: 25px"
                  class="text-center"
                  v-for="(item, index) in generalReport.kelas_tahun_ajaran
                    .peserta_ekstrakurikulers"
                  :key="item.id"
                >
                  <td style="height: 20px">{{ numberIndex(index) }}</td>
                  <td>
                    {{
                      item.ekstrakurikuler.english_name
                        ? item.ekstrakurikuler.english_name
                        : item.ekstrakurikuler.nama
                    }}
                  </td>
                  <td>{{ item.nilai ? item.nilai : "-" }}</td>
                  <td>
                    {{ checkString("extracurricular", item.nilai).description }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </template>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase"><h5>Attendance</h5></li>
          <div class="mt-2">
            <table class="w-50 table table-bordered mt-1">
              <tr>
                <td>Attendance</td>
              </tr>
              <tr>
                <td>
                  <table class="table-bordered" style="font-size: 11px">
                    <tr>
                      <td class="w-75">Sick</td>
                      <td>:</td>
                      <td>{{ generalReport.sick }}</td>
                      <td>day(s)</td>
                    </tr>
                    <tr>
                      <td class="w-75">Permit</td>
                      <td>:</td>
                      <td>{{ generalReport.excused }}</td>
                      <td>day(s)</td>
                    </tr>
                    <tr>
                      <td class="w-75">Unknown</td>
                      <td>:</td>
                      <td>{{ generalReport.absent }}</td>
                      <td>day(s)</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase"><h5>Achievement</h5></li>
          <div class="mt-2">
            <table class="table-bordered mt-1 w-100" style="font-size: 11px">
              <tr style="height: 30px" class="text-center">
                <td style="width: 10%">No</td>
                <td>Type</td>
                <td>Description</td>
              </tr>
              <template v-if="generalReport.penghargaan_murids.length">
                <tr
                  style="height: 25px"
                  v-for="(item, i) in generalReport.penghargaan_murids"
                  :key="item.id"
                  class="text-center"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.type || "-" }}</td>
                  <td>{{ item.description || "-" }}</td>
                </tr>
              </template>
              <tr style="height: 25px" class="text-center" v-else>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Teacher's Note</h5>
          </li>
          <div class="mt-2">
            <table class="table table-bordered mt-1" style="font-size: 11px">
              <tr class="text-center">
                <td>
                  {{
                    checkString(
                      "teacher_note",
                      scoring(generalReport.average_reports, [{ nilai: 75 }])
                        .remark
                    ).description
                  }}
                </td>
              </tr>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Parent's Note</h5>
          </li>
          <div class="mt-2 d-flex align-items-stretch">
            <div class="border w-50"></div>
            <div class="mx-1"></div>
            <div class="border w-50">
              <h6 class="border-bottom text-center py-2">Decision</h6>
              <div class="p-3">
                <h6 class="text-left font-size-10">
                  Based on the result that is achieved in semester 1 and 2,
                </h6>
                <h6 class="text-left font-size-10">
                  the student is decided :
                  <span class="font-weight-bold">Passed to {{ newGrade }}</span>
                </h6>
              </div>
            </div>
          </div>

          <div
            class="
              ttd
              mt-4
              mb-2
              font-size-10
              d-flex
              justify-content-between
              qrcode
            "
          >
            <img
              class="qrcode-image"
              v-if="base64QrCode"
              :src="base64QrCode"
              alt="qrcode"
            />
            <div class="">
              <h3 class="text-center font-size-12 invisible">.</h3>
              <h3 class="text-center font-size-12">Parent</h3>
              <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-5">
                ({{
                  generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nama
                    ? generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nama
                    : "-"
                }})
              </h3>
            </div>
            <div class="">
              <h3 class="text-center font-size-12 invisible">.</h3>
              <h3 class="text-center font-size-12">Class Teacher</h3>
              <div
                class="text-center mt-3 font-size-18"
                :class="
                  generalReport.verify_homeroom_date !== null ? '' : 'invisible'
                "
              >
                <!-- <a-icon type="check-square" style="color: #041930;"/> -->
                <!-- <check-icon /> -->
                <img src="@/assets/checkbox.png" width="18" alt="verified" />
              </div>
              <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
                ({{
                  generalReport.kelas_tahun_ajaran.kela.guru.nama
                    ? generalReport.kelas_tahun_ajaran.kela.guru.nama
                    : "-"
                }})
              </h3>
            </div>
            <div class="">
              <h3
                style="letter-spacing: 0.025em"
                class="text-center font-size-12"
              >
                Jakarta, {{ publicationDate }}
              </h3>
              <h3 class="text-center font-size-12">Principal</h3>
              <div
                class="text-center mt-3 font-size-18"
                :class="
                  generalReport.verify_principal_date !== null
                    ? ''
                    : 'invisible'
                "
              >
                <!-- <a-icon type="check-square" style="color: #041930;"/> -->
                <!-- <check-icon /> -->
                <img src="@/assets/checkbox.png" width="18" alt="verified" />
              </div>
              <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
                ({{
                  generalReport.kepsek.nama ? generalReport.kepsek.nama : "-"
                }})
              </h3>
              <!-- <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">({{generalReport.kepsek.nama ? generalReport.kepsek.nama : '-'}})</h3> -->
            </div>
          </div>
        </ol>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 40px;
          "
        >
          <hr class="mb-1" />
          <div class="d-flex justify-content-between mt-1 px-2">
            <div>
              {{ generalReport.kelas_tahun_ajaran.murid.nama }} /
              {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                generalReport.kelas_tahun_ajaran.kela.simbol
              }}
              /
              {{
                generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester ===
                "Genap"
                  ? 2
                  : 1
              }}
            </div>
            <div>page 6 of {{ totalPage }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="!page || (page && selectPage == 'rewardPunishment')"
        :style="
          format
            ? { ...format, height: format.height.split('px')[0] - 50 + 'px' }
            : {}
        "
        class="font-size-12"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img
              :src="institusi ? institusi.logo : `/${configSchool.logo}`"
              width="120px"
              alt
            />
          </div>
          <div class="ml-5">
            <h3 class="text-uppercase font-size-28">
              RECAPITULATION OF REWARD & PUNISHMENT
            </h3>
          </div>
        </div>
        <hr />
        <table class="table table-borderless mt-3">
          <tr class="font-size-12">
            <td class="table-title py-1">School Name</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1">
              {{ institusi ? institusi.nama : configSchool.name2 }}
            </td>
            <td class="table-title py-1">Class</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
                generalReport.kelas_tahun_ajaran.kela.simbol
              }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">Address</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1" style="width: 35%">
              {{ institusi ? institusi.alamat : configSchool.address }}
            </td>
            <td class="table-title py-1">Academic</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.tahun }}
              {{ generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">Student Name</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.murid.nama }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">NIS</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.murid.nis }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">NISN</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ generalReport.kelas_tahun_ajaran.murid.nisn }}
            </td>
          </tr>
        </table>
        <hr />
        <h5 class="text-uppercase mb-4 subtext"><b>Reward & Punishment</b></h5>
        <table class="table-bordered w-100 text-center" style="font-size: 11px">
          <tr style="height: 30px" class="text-uppercase">
            <td>No.</td>
            <td style="width: 30%">Reward</td>
            <td>Score</td>
            <td>Frequency</td>
          </tr>
          <template v-if="rewards.length">
            <tr
              style="height: 25px"
              v-for="(item, i) in rewards"
              :key="item.id"
              class="text-center"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item.reward_punishment.description || "-" }}</td>
              <td>{{ item.reward_punishment.score || "-" }}</td>
              <td>{{ item.frequency || "-" }}</td>
            </tr>
          </template>
          <tr style="height: 25px" class="text-center" v-else>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
        <br />
        <table class="table-bordered w-100 text-center" style="font-size: 11px">
          <tr style="height: 30px" class="text-uppercase">
            <td>No.</td>
            <td style="width: 30%">Punishment</td>
            <td>Score</td>
            <td>Frequency</td>
          </tr>
          <template v-if="punishments.length">
            <tr
              style="height: 25px"
              v-for="(item, i) in generalReport.penghargaan_murids"
              :key="item.id"
              class="text-center"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item.reward_punishment.description || "-" }}</td>
              <td>{{ item.reward_punishment.score || "-" }}</td>
              <td>{{ item.frequency || "-" }}</td>
            </tr>
          </template>
          <tr style="height: 25px" class="text-center" v-else>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
        <br />
        <h5 class="text-uppercase mb-4">
          <b>Recapitulation of Reward & Punishment</b>
        </h5>
        <div
          class="d-flex justify-content-center align-items-center w-100 mt-1"
          style="height: 50px; border: 1px solid black"
        >
          <div>{{ totalRewardPunishment }}</div>
        </div>
        <div
          class="
            ttd
            mt-4
            mb-2
            font-size-10
            d-flex
            justify-content-between
            qrcode
          "
        >
          <img
            class="qrcode-image"
            v-if="base64QrCode"
            :src="base64QrCode"
            alt="qrcode"
          />
          <div class="">
            <h3 class="text-center font-size-12 invisible">.</h3>
            <h3 class="text-center font-size-12">Parent</h3>
            <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-5">
              ({{
                generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nama
                  ? generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nama
                  : "-"
              }})
            </h3>
          </div>
          <div class="">
            <h3 class="text-center font-size-12 invisible">.</h3>
            <h3 class="text-center font-size-12">Class Teacher</h3>
            <div
              class="text-center mt-3 font-size-18"
              :class="
                generalReport.verify_homeroom_date !== null ? '' : 'invisible'
              "
            >
              <!-- <a-icon type="check-square" style="color: #041930;"/> -->
              <!-- <check-icon /> -->
              <img src="@/assets/checkbox.png" width="18" alt="verified" />
            </div>
            <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
              ({{
                generalReport.kelas_tahun_ajaran.kela.guru.nama
                  ? generalReport.kelas_tahun_ajaran.kela.guru.nama
                  : "-"
              }})
            </h3>
          </div>
          <div class="">
            <h3
              style="letter-spacing: 0.025em"
              class="text-center font-size-12"
            >
              Jakarta, {{ publicationDate }}
            </h3>
            <h3 class="text-center font-size-12">Principal</h3>
            <div
              class="text-center mt-3 font-size-18"
              :class="
                generalReport.verify_principal_date !== null ? '' : 'invisible'
              "
            >
              <!-- <a-icon type="check-square" style="color: #041930;"/> -->
              <!-- <check-icon /> -->
              <img src="@/assets/checkbox.png" width="18" alt="verified" />
            </div>
            <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
              ({{
                generalReport.kepsek.nama ? generalReport.kepsek.nama : "-"
              }})
            </h3>
            <!-- <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">({{generalReport.kepsek.nama ? generalReport.kepsek.nama : '-'}})</h3> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import moment from 'moment'
import QRCode from 'qrcode'
// const CheckIcon = () => import('@/components/app/CheckIcon')
export default {
  props: {
    configSchool: {
      type: Object,
    },
    generalReport: {
      type: Object,
    },
    page: {
      type: Boolean,
    },
    format: {
      type: Object,
      // required: true,
    },
    print: {
      type: Boolean,
      // required: true,
    },
  },
  // components: { CheckIcon },
  data() {
    return {
      base64QrCode: null,
      textCaption: null,
      baseUrl: window.location.origin,
      selectPage: 1,
      totalPage: 6,
      tableGradeHeight: 110,
    }
  },
  mounted() {
    if (this.generalReport) {
      // this.checkString('social', 'A')
      this.generateBase64QrCode(`${this.generalReport.uuid}`)
      const guruWalas = this.generalReport.kelas_tahun_ajaran.kela.guru.nama ? this.generalReport.kelas_tahun_ajaran.kela.guru.nama : ''
      const kepalaSekolah = this.generalReport.kepsek.nama ? this.generalReport.kepsek.nama : ''
      this.textCaption = `This document was digitally signed by the principal${kepalaSekolah !== '' ? ` (${kepalaSekolah})` : ''}, and the class teacher${guruWalas !== '' ? ` (${guruWalas})` : ''}. No wet/physical signature required`
    }
  },
  methods: {
    moment,
    checkString(type, option) {
      const variableAvalaible = config.templateVariables
      let target = {
        alias: '-',
        description: '-',
      }
      const findTemplate = this.generalReport.template.find(item => item.type === type && item.option === option)
      // console.log(`findTemplate`, findTemplate)
      if (findTemplate) {
        const matches = findTemplate.description.matchAll(/#(.*?)#/g)

        const isHavingCode = Array.from(matches, x => x[0])

        if (isHavingCode.length) {
          isHavingCode.forEach(el => {
            const findCode = variableAvalaible.find(variable => variable.code.split(' ')[0] === el)
            let replaceWith = ' '
            if (findCode) {
              if (el === '#STUDENT_NAME#') {
                replaceWith = this.generalReport.kelas_tahun_ajaran.murid.nama
              } else if (el === '#TEMPLATE_TYPE#') {
                replaceWith = findTemplate.type
              } else if (el === '#ALIAS#') {
                replaceWith = findTemplate.alias
              }
              findTemplate.description = findTemplate.description.replace(el, replaceWith)
            }
            // console.log('loop', target, description)
          })
          // console.log('dont have code')
        }
        // console.log(target)
        target = findTemplate
      }
      // console.log('target', target)
      return target
    },
    async generateBase64QrCode(uuid) {
      try {
      // console.log(this.base64IsReady)
        const link = this.baseUrl + '/public/report-preview/' + uuid
        this.base64QrCode = await QRCode.toDataURL(link)
        // const canvas = document.getElementsByClassName('qrcode')[0].getElementsByTagName('canvas')
        // const canvas = document.getElementsByClassName('qrcode')[0].getElementsByTagName('canvas')[0]
        // const canvas2 = document.getElementById('canvas2QrCode')
        // console.log(canvas, 'oaao', canvas2)
        // await QRCode.toCanvas(canvas2, link)
      // QRCode.toCanvas(canvass, link, (err, res) => {
      //   if (err) return console.log(err)
      //   console.log('success')
      // })
      // this.base64IsReady = true
      } catch (err) {
        console.log(err)
      }
    },
    numberIndex(number) {
      return ++number
    },
    mapels(data, type) {
      if (!data.length) {
        return []
      }

      const find = data.filter(el => el.mata_pelajaran.kelompok.nama === type)

      return find
    },
    scoring(score, kbm) {
      const scoringStatus = {
        remark: '-',
      }

      if (score !== null && kbm.length) {
        if (score > 89 && score <= 100) {
          scoringStatus.remark = 'A'
        } else if (score > 79 && score <= 89) {
          scoringStatus.remark = 'B'
        } else if (score >= kbm[0].nilai && score <= 79) {
          scoringStatus.remark = 'C'
        } else if (score < kbm[0].nilai) {
          scoringStatus.remark = 'D'
        }
      }

      console.log('scoring', score, scoringStatus)
      return scoringStatus
    },
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    rewards() {
      if (this.generalReport.report_reward_punishments?.length) {
        return this.generalReport.report_reward_punishments.filter(reward => reward.reward_punishment.type === 'reward')
      }
      return []
    },
    punishments() {
      if (this.generalReport.report_reward_punishments?.length) {
        return this.generalReport.report_reward_punishments.filter(punishment => punishment.reward_punishment.type === 'punishment')
      }
      return []
    },
    totalRewardPunishment() {
      let score = 100
      if (this.rewards.length || this.punishments.length) {
        this.rewards.forEach(reward => { score += (reward.reward_punishment.score * reward.frequency) })
        this.punishments.forEach(punishment => { score += (punishment.reward_punishment.score * punishment.frequency) })
      }
      return score
    },
    newGrade() {
      let res = 'Grade '
      if (this.generalReport?.kelas_tahun_ajaran) {
        const oldGrade = this.generalReport.kelas_tahun_ajaran.kela.tingkat
        if (oldGrade === 'VII') res += 'VIII (Eight)'
        else if (oldGrade === 'VIII') res += 'IX (Nine)'
        else if (oldGrade === 'IX') res = 'Senior High School'
      }

      return res
    },
    publicationDate() {
      if (this.generalReport?.kelas_tahun_ajaran?.kela?.tahun_ajaran?.report_settings[0]?.tanggal_publikasi) {
        const date = moment(this.generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.report_settings[0].tanggal_publikasi).format('DD  MMMM YYYY')
        return date
      }
      return '-'
    },
  },
}
</script>

<style lang="scss">
#card-body-wrapper {
  .subText {
    font-size: 1rem !important;
  }

  .table-bordered {
    border-collapse: inherit !important;
    & > tr {
      & > td {
        border-top-width: 0;
        border-left-width: 0;

        &:last-child {
          border-right-width: 0;
        }
      }

      &:last-child {
        & > td {
          border-bottom-width: 0;
        }
      }
    }
  }

  .border,
  .border-bottom {
    border-color: black !important;
  }
}
</style>
